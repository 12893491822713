import './App.css';
import Index from './components/outline';
import Snowfall from './components/Snowfall';
import ScrollProgressBar from './components/ScrollProgressBar';

function App() {
  return (
    <div className="App">
      <Index />
      <ScrollProgressBar />
      <Snowfall />
    </div>
  );
}

export default App;
