export const projectsDataOSS = [
    {
      Owner: "julep-ai",
      linkedin: "https://www.linkedin.com/company/julep-ai",
      Name: "Julep",
      github: "https://github.com/julep-ai/julep",
      Description:
        "Julep is a platform for creating AI agents that remember past interactions and can perform complex tasks. It offers long-term memory and manages multi-step processes. Julep enables the creation of multi-step tasks incorporating decision-making, loops, parallel processing, and integration with numerous external tools and APIs.",
      Repo: "https://github.com/julep-ai/julep",
      TechStack: ["Python", "Jupyter notebook", "AI", "LLM", "Node.js"],
    },
    {
      Owner: "Hexastack",
      linkedin: "",
      Name: "Hexabot",
      github: "https://github.com/hexastack/hexabot",
      Description:
        "Hexabot is an open-source AI chatbot / agent builder. It allows you to create and manage multi-channel and multilingual chatbots / agents with ease.",
      Repo: "https://github.com/hexastack/hexabot",
      TechStack: ["TypeScript", "Python", "SCSS", "JavaScript", "CSS", "Shell"],
    },
    {
        Name: 'Coming Soon',
        Repo: '',
        Owner: '',
        github: '',
        linkedin: '',
        Description: `More OSS projects Coming Soon, , Keep Contributing...`,
        TechStack: [],
      },
  ];
  